import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux';
import counterReducer from './Slice/Reducers'
import webReducer from './Slice/WebSlice'
import englishReducer from './Slice/EnglishSlice'
import answersReducer from './Slice/AnswersSlice'

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // 使用local storage
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
 

const rootReducer = combineReducers({
  webReducer,
  counterReducer,
  englishReducer,
  answersReducer
});

// const persistConfig = {
//   key: 'root',
//   storage,
//   stateReconciler: autoMergeLevel2 // 用于合并重叠的state
// };

// const persistedReducer = persistReducer(persistConfig, rootReducer);

let store = configureStore({
  reducer: rootReducer
});
//let persistor = persistStore(store);

export {
  store
  //persistor
}