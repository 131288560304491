import { createSlice } from '@reduxjs/toolkit'
import loginState from '../State/LoginState'
import StatusModel from '../../Model/StatusModel'
export const counterSlice = createSlice({
  name: 'login',
  initialState: loginState,
  reducers: {
    setShowingLoginModal : (state, val) => {
        console.log(val.payload)
        state.isShowingLoginAndRegistrationModal = val.payload
    },
    setAccount : (state, val) => {
      console.log(state.registerResult)
      state.userInfo = val.payload
      state.registerResult = !state.registerResult
    },
    setRegisterResult : (state, val) => {
      console.log(state.registerResult)
      state.registerResult = !state.registerResult
    },
    setLoginResult : (state, val) => {
      console.log(val.payload, 'loginResult')
      state.loginStatus = val.payload
    },
    setLogOut: (state, val) => {
      state.loginStatus = StatusModel.logout
    },
    setRememberedUser: (state, val) => {
      state.rememberedUser = val.payload
    },
    setCheckBoxValue: (state, val) => {
      state.checkBoxValue = val.payload
    },
    updateUserInfoAfterLogin: (state, val) => {
      state.userInfo = val.payload
    }

  }
})
export const { 
    setShowingLoginModal,
    collectUserInfo,
    setAccount,
    setRegisterResult,
    setLoginResult,
    setLogOut,
    setRememberedUser,
    setCheckBoxValue,
    updateUserInfoAfterLogin
} = counterSlice.actions

export default counterSlice.reducer