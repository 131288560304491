import axios from 'axios';
const axiosApi = axios.create({
  proxy: {
    host: 'localhost:3001', // 代理服务器地址
    port: 3001,        // 代理服务器端口
    protocol: 'http'   // 代理服务器协议
  }
});

// 请求拦截器
axiosApi.interceptors.request.use(
  config => {
    // 在发送请求之前做些什么
    console.log('请求拦截器');
    if (localStorage.getItem('authorization') != null) {
        config.headers['Authorization'] = localStorage.getItem('authorization')
    }
    console.log(config)
   
    return config;
  },
  error => {
    // 对请求错误做些什么
    console.error('请求拦截器错误', error);
    return Promise.reject(error);
  }
);

// 响应拦截器
axiosApi.interceptors.response.use(
  response => {
    console.log(response, 'ddd')
    // 对响应数据做些什么
    const token = response.headers.authorization
    if (token != undefined) {
        localStorage.setItem('authorization', token)
    }
    return response;
  },
  error => {
    // 对响应错误做些什么
    console.error('响应拦截器错误', error);
    return Promise.reject(error);
  }
);

//axiosApi.use(proxy)

export default axiosApi;