import React from 'react'

export default function AnswerResult(props) {
  const result = props.result
  const answer = props.answer
  return (
    <div>
        {showResult(result)}
    </div>
  )

  function showResult(result) { 
    return  <div className='answerFont answerResult'>
                回答: {result ? '(✅)' : '(❌)'}
                <span style={{marginLeft: '36px'}}>{answer}</span>
             </div>
  }
}
