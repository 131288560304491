const EnglishState = {
    currentNumber: 0,
    allQuestions :  [
        {
        "number":"1",
        "content" :"现代警察的含义一般是指具有武装性质的维护社会秩序、保卫国家安全的国家 ________力量。",
        "optionContent" : [
            {"optionKey": "A", "optionValue" : "行政"},
            {"optionKey":"B" , "optionValue": "司法"},
            {"optionKey":"C" , "optionValue": "军事"},
            {"optionKey":"D" , "optionValue": "服务"}
        ],
        "answer":"A",
        "analyze" : "【答案】A。解析：现代警察是具有武装性质的维护社会秩序、保卫国家安全的国家行政力量。故本题答案为 A。"
    },
 {
        "number":"2",
        "content" :"下列关于警察的说法哪一项是不正确的？",
        "optionContent" : [
            {"optionKey": "A", "optionValue" : "国家本质决定警察本质"},
            {"optionKey":"B" , "optionValue": "国家性质决定警察性质"},
            {"optionKey":"C" , "optionValue": "世界上各国警察的本质都是相同的"},
            {"optionKey":"D" , "optionValue": "我国人民公安的性质与世界警察的性质是相同的"}
        ],
        "answer":"D",
        "analyze" : "【答案】D。解析：我国人民公安的性质是人民民主专政中具有武装性质的刑事司法力量和治安行政力量。因为国家性质决定警察性质，故我国公安机关性质与世界警察性质不同。故本题答案为 D。"
    },
 {
        "number":"3",
        "content" :"警察运用行政管理的手段，维护一定社会制度下的社会秩序，属于警察的________ 职能。",
        "optionContent" : [
            {"optionKey": "A", "optionValue" : "社会文化"},
            {"optionKey":"B" , "optionValue": "经济管理"},
            {"optionKey":"C" , "optionValue": "政治镇压"},
            {"optionKey":"D" , "optionValue": "社会管理"}
        ],
        "answer":"D",
        "analyze" : "【答案】D。解析：政治镇压职能是指警察适用暴力，对威胁统治阶级的政治统治与国家安全的政治势力实行镇压，体现了警察具有鲜明的阶级性。社会管理职能是指警察运用行政管理手段，维护一定社会制度下的社会秩序。警察的这一职能体现了广泛的社会性和群众性。故本题答案为 D。"
    },
{
        "number":"4",
        "content" :"我国的公安机关具有：",
        "optionContent" : [
            {"optionKey": "A", "optionValue" : "超阶级性"},
            {"optionKey":"B" , "optionValue": "非政治性"},
            {"optionKey":"C" , "optionValue": "鲜明的阶级性"},
            {"optionKey":"D" , "optionValue": "非暴力性"}
        ],
        "answer":"C",
        "analyze" : "【答案】C。解析：公安工作有鲜明的阶级性和广泛的社会性。所谓阶级性，即警 察与国家一致的特点，这是各国警察共有的特性。国家要求警察必须与国体一致，与政 体一致，与国家意志一致，成为国家忠诚的统治与管理的工具。我国公安工作也不例外。 我国公安工作必须与我国执政党——中国共产党保持一致，因此具有鲜明的阶级性。故 本题答案为 C。"
    },
 {
        "number":"5",
        "content" :"《刑事诉讼法》规定，公安机关负责人的回避，由：",
        "optionContent" : [
            {"optionKey": "A", "optionValue" : "上级公安机关决定"},
            {"optionKey":"B" , "optionValue": "同级人民检察院检察长决定"},
            {"optionKey":"C" , "optionValue": "同级人民检察院检察委员会决定"},
            {"optionKey":"D" , "optionValue": "上级人民检察院检察委员会决定"}
        ],
        "answer":"C",
        "analyze" : "【答案】C。解析：《刑事诉讼法》第三十一条规定，审判人员、检察人员、侦查 人员的回避，应当分别由院长、检察长、公安机关负责人决定；院长的回避，由本院审 判委员会决定；检察长和公安机关负责人的回避，由同级人民检察院检察委员会决定。 故本题答案为 C。"
    }
]
}


export default EnglishState