import React, { useMemo } from 'react'
import './NavigationBar.css'
import '../../index.css'
import { useSelector, useDispatch } from 'react-redux'
import { setShowingLoginModal } from '../../Redux/Slice/Reducers'
import {Avatar, Button} from 'antd'
import {IconSun, IconMoon} from '@tabler/icons-react'
import { setTheme } from '../../Redux/Slice/WebSlice'
import LogOut from './LogOut'
import StatusModel from '../../Model/StatusModel.js'


export default function NavigationBar() {
  const dispatch = useDispatch()
  const appTheme = useSelector(state => state.webReducer.theme)
  const loginUser = useSelector(state => state.counterReducer)
  const englishRecord = () => {
    
  }
  const loginAndRegister = () => {
    dispatch(setShowingLoginModal(true))
  }
  const updateThemes = () => {
    dispatch(setTheme())
  }
  const themeIcon = useMemo(() => {
    return appTheme == 'light-mode' ? <IconSun
      size={24} // set custom `width` and `height
      stroke={2}  // set `stroke-width`
      strokeLinejoin="miter" // override other SVG props
    /> : <IconMoon
        size={24} // set custom `width` and `height
        stroke={2}  // set `stroke-width`
        strokeLinejoin="miter" // override other SVG props
        color='white' // override
      />
  }, [appTheme])

  return (
    <div className='navigation-bar'>
        {/* <div className='webTitle'>
          灰烬冒险
        </div>
        <div className='englishRecordText'>
          <Button type='text' size='small' 
                  onClick={englishRecord}
                  className='navigationTextHeader textFontSizeForHeader textFontWeightForHeader'
          >英语打卡</Button>
        </div> */}
        {/* <div className='navigationSun'>
          <Button 
              type='text' 
              icon={themeIcon}
              onClick={updateThemes}
          >
          </Button>
          <div className='registerAndLogin'>
          {showUserNameOrHidden()}
          </div>
        </div> */}
        
    </div>
  )
  function showUserNameOrHidden() {
    if (loginUser.loginStatus == StatusModel.succeeded) {
      return <div>
          <LogOut/>
        </div>
    } 
    return <Button type='text' 
              size='small' 
              className='navigationTextHeader textFontSizeForHeader textFontWeightForHeader'
              onClick={loginAndRegister}
          >注册/登录</Button>
  }
}
