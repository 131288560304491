import React, {useState} from 'react'
import {Card, Radio, Space, List} from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { updateAnswers } from '../../../Redux/Slice/AnswersSlice'
import AnswerResult from './AnswerResult'
export default function QuestionCard(props) {
    const dispatch = useDispatch()
    const englishObject = props.questionData
    const currentIndex = props.currentIndex

    const userAnswer = useSelector(state => state.answersReducer.userAnswers)
    const isShowingAnswer = useSelector(state => state.answersReducer.isShowingAnswer)
    const standardAnswers = useSelector(state => state.answersReducer.standardAnswers)
    const userChoice = (val) => {
        console.log(val.target.value)
        dispatch(updateAnswers({answer :val.target.value}))
    }
    console.log(englishObject,'111')
  return (
    <div style={{display:'flex', flexDirection:'row', alignItems:'normal', 
        marginLeft:'12px', marginRight:'12px'
    }}>
    <span className='answerFont answerColor'>{englishObject.number}</span>
    <Card
        title= {
                    <div className='englishCard'>
                    {englishObject.content}
                    </div>
                }
        bordered={false}
        className='cardBg'
    >
    <Radio.Group    
        onChange={userChoice} 
        value={userAnswer}
        optionType='default'
    >
        <Space direction="vertical">
        {
            englishObject.optionContent.map((item, key) => 
            <Radio key={key} className='answerColor answerFont'
                    value={item.optionKey}
            >
            {item.optionKey + ". "+ item.optionValue }
            </Radio>
        )
        }
    </Space>
    </Radio.Group>
            {showResult()}
    </Card>
    </div>
  )

  function showResult() {
    if (isShowingAnswer) {
    return <AnswerResult 
        result = {userAnswer == englishObject.answer}
        answer = {englishObject.analyze}
        ></AnswerResult> 
    }
  }
}
