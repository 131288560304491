import { createSlice } from '@reduxjs/toolkit'
import WebState from '../State/WebState'

export const webSlice = createSlice({
  name: 'WebSlice',
  initialState: WebState,
  reducers: {
    setTheme : (state, val) => {
        state.theme = state.theme == "light-mode" ? 
        "dark-mode" : "light-mode" 
    },
    setShowLoadingView : (state, val) => {
      console.log(state.showLoadingView)
      state.showLoadingView = !state.showLoadingView
    }
  }
})

export const { 
    setTheme,
    setShowLoadingView
} = webSlice.actions

export default webSlice.reducer