import React, { useEffect, useMemo, useState } from 'react'
import '../../../index.css'
import './EnglishContent.css'
import {Button, List} from 'antd'
import '../../../index.css'
import QuestionCard from './QuestionCard'
import AnswerCard from './AnswerCard'
import { useDispatch, useSelector } from 'react-redux'
import { setShowLoadingView } from '../../../Redux/Slice/WebSlice'
import {setEnglishQuestions, updateCurrentQuestion} from '../../../Redux/Slice/EnglishSlice'
import {setShowingLoginModal, updateUserInfoAfterLogin} from '../../../Redux/Slice/Reducers'
import {setStandardAnswers, finishQuestion, resetUserAnswer} from '../../../Redux/Slice/AnswersSlice'

import axiosApi from '../../../Functions/AxiosApi'
import StatusModel from '../../../Model/StatusModel'
import todayTime from '../../../Model/todayTime'
export default function Question() {
  const questionsArray = useSelector(state => state.englishReducer.allQuestions)
  const questionsNumber = useSelector(state => state.englishReducer.currentNumber)

  const userAnswer = useSelector(state => state.answersReducer.userAnswers)
  const isUserLoggedIn = useSelector(state => state.counterReducer.loginStatus)
  const userInfo = useSelector(state => state.counterReducer.userInfo)
  let recordArray = useSelector(state => state.counterReducer.userInfo.recordedHistory) 

  const dispatch = useDispatch()
  const getScore = () => {
    //检查登录没？
    if (isUserLoggedIn == StatusModel.succeeded) {
      console.log(userAnswer)
      dispatch(setShowLoadingView())
      let arr = recordArray
      if(!arr.includes(todayTime)){
        arr = [...arr, todayTime]
      }
      let recordAnswer = {}
      recordAnswer[`${todayTime}`] = userAnswer
      axiosApi.post('api/answers/myScore',{
        answer : recordAnswer,
        record : arr,
        username: userInfo.username
      }).then((res) => {
          dispatch(setShowLoadingView())
          console.log(res.data)
          //set userInfo
          dispatch(updateUserInfoAfterLogin(res.data.user))
          //set answer
          dispatch(setStandardAnswers(res.data.answerForToday))
        }).catch((err) => {
          dispatch(setShowLoadingView())
          console.log(err)
        })

    } else {
      console.log("请登录")
      dispatch(setShowingLoginModal(true))
    }
  
  }

  const completeQuestion = () => {
      dispatch(finishQuestion())
  }
  const nextQuestion = () => {
      dispatch(updateCurrentQuestion())
      //clear 记录 answer + isShowing
      dispatch(resetUserAnswer())
  }
  return (
    <div className='englishQuestionConent'>
      {/* <List
        itemLayout = "vertical"
        bordered = 'true'
        dataSource={Array.from(questionDataSource.allQuestions)}
        renderItem={(item) => (
          
          <List.Item>
            
          </List.Item>
        )}
      /> */}
      <QuestionCard 
        questionData = {questionsArray[questionsNumber]} 
        currentIndex = {0}
      />    
      <div style={{marginTop:'24px'}}>
        <Button type='default' onClick={() => {
            completeQuestion()
        }}>确认</Button>

        <Button type='primary' 
        style={{marginLeft:'24px'}}
        onClick={() => {
          nextQuestion()
        }}
        >下一题</Button>
      
      </div>

      
    </div>
  )

  function showSubmitButton() {
  
    const val = userAnswer.includes('')
    if (!recordArray?.includes(todayTime)) {
     return <div
        disabled = {val}
        className={val ? 'submitButton' : 'submitButton enableClick'}
        onClick={() => {
          if (!val) {
            getScore()
          }
        }}
        
      >提交</div>
    }
  }

  function getRecordTime() {
    let today = new Date();
    let year = today.getFullYear(); // 获取年份
    let month = today.getMonth() + 1; // 获取月份，加1因为月份从0开始
    let day = today.getDate();
    console.log([year, month , day].join('-'))
    return [year, month , day].join('-')
  }

  function loadDataForView() {
    axiosApi.get('/api/question').then((res) => {
      console.log(res.data, 'what we get')
      dispatch(setEnglishQuestions({questions:res.data}))
    }).catch((err) => {
      console.log(err)
    })
  }

  function isThereQuestions() {
    if (questionsArray.length > 0) {
      return <div className='submitButtonBox'>
        <AnswerCard></AnswerCard>

        {showSubmitButton()}
      </div>
    }
  }
}
