import React, { useEffect } from 'react'
import NavigationBar from './AppHeader/NavigationBar'
import '../index.css'
import './App.css'
import { useSelector, useDispatch } from 'react-redux'
import Announcement from './Announcement/Announcement'
import Question from './AppContent/EnglishLearning/Question'

export default function App() {
  const isLoginModalOpen = useSelector(state => {
    console.log(state)
    return state.counterReducer.isShowingLoginAndRegistrationModal
  })
  const appTheme = useSelector(state => state.webReducer.theme)
  const showLoadingView = useSelector(state => state.webReducer.showLoadingView)
  const {userInfo} = useSelector(state => state.counterReducer)

  useEffect(() => {
    if (userInfo?.username){
      //send login request
      console.log(userInfo?.username, userInfo?.password, "Hi")
    } else {
      console.log('no user info')
    }
  }, [])

  return (
    <div className={'rootDiv' + " " + appTheme}>
        <NavigationBar></NavigationBar>
        <div className='content'>
          {/* <Announcement></Announcement> */}
          <Question></Question>
        </div>
        {/* <div className='belowNavigationText'>
            应用改变世界 从改变我开始
        </div> */}
    </div>
  )
}

/*

<div className='content'>
          <EnglishContent></EnglishContent>
          <Divider
              style={{
                borderColor: '#7cb305',
                marginBottom:'60px'
              }}
            >
              <span className='dividerLine'>打卡请完成</span>
              <IconArrowDown
               size={24} // set custom `width` and `height
               stroke={2}  // set `stroke-width`
               strokeLinejoin="miter" // override other SVG props
               color={appTheme == 'dark-mode' ? "orange" : "green"} // override
              ></IconArrowDown>
              <span className='dividerLine'>下方的题目</span>
          </Divider>
        </div>
        
        <div className='content2'>
         <Question/>
        </div>
        <Spin
          indicator={
          <LoadingOutlined
          style={{
            fontSize: 48
          }}/>}
          fullscreen
          delay = '500'
          spinning = {showLoadingView}
        />
        {isLoginModalOpen && <LoginForm />}
*/