import React from 'react'
import './registration.css'
import { Button, Result, Form, Input } from 'antd';
import axios from 'axios';
import { setAccount, setRegisterResult } from '../../Redux/Slice/Reducers';
import { useDispatch, useSelector } from 'react-redux';
import {Link, NavLink, useNavigate, useParams } from 'react-router-dom'
import axiosApi from '../../Functions/AxiosApi';
export default function RegistrationView() {
    const dispatch = useDispatch()
    const showResultView = useSelector(state => state.counterReducer.registerResult)
    const myNavigation = useNavigate()
    const onFinish = (values) => {
        console.log('Success:', values);
        //send registration request
        beginRegistration(values)
      };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    const updateShowResultView =() => {
        dispatch(setRegisterResult(""))
    }
    const loginImmediately = () => {
        updateShowResultView()
        myNavigation('/')
    }
  return (
        <div className='registrationBox'>
            <div className='bgImage'></div>

            <div className='formLayout'>
                    <Form
                        name="basic"
                        // labelCol={{
                        //   span: 8,
                        // }}
                        // wrapperCol={{
                        //   span: 16,
                        // }}
                        style={{
                        maxWidth: 600,
                        }}
                        initialValues={{
                        remember: true,
                        }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        className='formRight'
                    >
                    <h1>欢迎注册</h1>
                    <Form.Item
                    label="用户名"
                    name="username"
                    rules={[
                        {
                        required: true,
                        message: 'Please input your username!',
                        },
                        {
                            validator: async (rule, value) => {
                            if (value.length == 0) {
                                return Promise.resolve() 
                            }
                            //判断用户名是否存在 发请求
                            await axiosApi.get(`/api/register/enableUserName?username=${value}`).then(
                                (res) => {
                                    if (res.data == 'existedUser') {
                                        return Promise.reject(new Error('该用户名已被占用啦'));
                                    }
                                    //不存在就继续
                                    return Promise.resolve() 
                                }
                            )
                            },
                        },
                    ]}
                    className = 'formItemSpan'
                    >
                    <Input />
                    </Form.Item>

                    <Form.Item
                    label= '密　码'
                    name="password"
                    rules={[
                        {
                        required: true,
                        message: 'Please input your password!',
                        },

                    ]}
                    className = 'formItemSpan'
                    
                    >
                    <Input.Password />
                    </Form.Item>

                    <Form.Item
                    style={{textAlign:'center'}}
                    className = 'formItemSpan'

                    >
                    <Button type="primary" htmlType="submit">
                        注册
                    </Button>
                    </Form.Item>
                </Form>
            </div>
            <div className={showResultView ? 'resultDiv': 'showOrHidden'}>
            <Result
                status="success"
                title="恭喜！您的账号已成功注册"
                subTitle="点击下方登录按钮，即可直接登录🎉"
                extra={[
                <Button  key="console" onClick={updateShowResultView}>
                    取消
                </Button>,
                <Button type="primary" key="buy"
                    onClick={loginImmediately}
                >立即登录</Button>,
                ]}
            />
            </div>
        </div>

  )

  function checkIsEnabledUserName(val) {
    axiosApi.get(`/api/enableUserName?username=${val}`).then(
        (res) => {
            if (res.data == 'existedUser') {
                return Promise.reject(new Error('该用户名已被占用啦'));
            }
            //不存在就继续
            return Promise.resolve() 
        }
    ).catch((err) => {
        return Promise.reject(new Error('未知错误'));
    })
  }
  function beginRegistration(val) {
    axiosApi.post(`/api/register`,val)
    .then((res) => {
        
        if (res.data.registrationResult == "succeeded") {
            //保存状态 弹窗 可以登录
            dispatch(setAccount(res.data.account))
            console.log(res.data)
        }
    }
    ).catch((err) => {
        console.log(err)
    })
  }
}
