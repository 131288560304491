const loginState ={
    isShowingLoginAndRegistrationModal: false,
    userInfo : {},
    loginStatus:'',
    registerResult: false,
    logintButtonTitle: "登录",
    rememberedUser: {},
    checkBoxValue : false

}

export default loginState