import { createSlice } from '@reduxjs/toolkit'
import AnswersState from '../State/AnswersState'
export const answersSlice = createSlice({
  name: 'EnglishAnswers',
  initialState: AnswersState,
  reducers: {
    updateAnswers: (state, action) => {
        const answer = action.payload.answer
        state.userAnswers = answer
    },
    setStandardAnswers: (state, action) => {
      if(action.payload.standardAnswers) {
        state.standardAnswers = action.payload.standardAnswers
      } else {
        state.standardAnswers = action.payload
      }
    },
    setUserAnswers: (state, action) => {
      console.log(action.payload,"here is user answers")
      if (action.payload.length > 0 ) {
        state.userAnswers = action.payload
      }
    },
    clearUserAnswers: (state, action) => {
      state.userAnswers = ['','','','','']
    },
    finishQuestion: (state, action) => {
      state.isShowingAnswer = true
    },
    resetUserAnswer: (state, action) => {
      state.userAnswers = ''
      state.isShowingAnswer = false
    }
  }
})

export const { 
    updateAnswers,
    setStandardAnswers,
    clearUserAnswers,
    setUserAnswers,
    finishQuestion,
    resetUserAnswer
} = answersSlice.actions

export default answersSlice.reducer