import { createSlice } from '@reduxjs/toolkit'
import EnglishState from '../State/EnglishState'

export const englishSlice = createSlice({
  name: 'EnglishSlice',
  initialState: EnglishState,
  reducers: {
    setEnglishQuestions: (state, action) => {
      console.log(action.payload, "sdhsjafkl");
      state.allQuestions = action.payload.questions
    },
    updateCurrentQuestion: (state, action) => {
      state.currentNumber += 1
      
    }
  }
})

export const { 
    setEnglishQuestions,
    updateCurrentQuestion
} = englishSlice.actions

export default englishSlice.reducer