import React from 'react'
import './Announcement.css'
import { LikeOutlined, MessageOutlined, StarOutlined, YahooFilled } from '@ant-design/icons';
import { Avatar, List, Space } from 'antd';

const personalData = [
    {
       title: `ant design part`,
        avatar: `https://api.dicebear.com/7.x/miniavs/svg?seed=`,
        description:
          '2024-11-12',
        content:
          `I bought a car, what do you want to share with me about your car? If you also like 
          car, please tell me in Englishught a car, what do you want to share with me about your car? If you also like 
          car, please tell mr, what do you want to share with me about your car? If you also like 
          car, please tell me in Englishught a car, what do you want to share with me about your car? If you also like 
          car, please tell m`,
    }
]
const data = Array.from({
    length: 1,
  }).map((_, i) => ({
    title: `ant design part ${i}`,
    avatar: `https://api.dicebear.com/7.x/miniavs/svg?seed=${i}`,
    description:
      '2024-11-12',
    content:
      `I bought a car, what do you want to share with me about your car? If you also like 
      car, please tell me in English`,
  }));
  const IconText = ({ icon, text }) => (
    <Space className='userInteractionSpace'>
      {React.createElement(icon)}
      {text}
    </Space>
  );

export default function ThingsCard() {
    const titleWasClicked = () => {
        console.log('you have clicked on the title')
    }
  return (
    <div>
        <List
            itemLayout="vertical"
            size="large"
            pagination={{
                onChange: (page) => {
                    console.log(page);
                },
                pageSize: 5,
                align: 'center'
            }}
            dataSource={data}
            className='cardForThings'
            renderItem={(item) => (
            <List.Item
                key={item.title}
                actions={[
                    <div onClick={() => {console.log("favorite")}}>
                        <IconText icon={StarOutlined} 
                        text="156" 
                        key="list-vertical-star-o" 
                        />
                    </div>,
                    <div onClick={() => {console.log("like")}}>
                        <IconText icon={LikeOutlined} 
                        text="156"
                        key="list-vertical-like-o" />
                    </div>,
                    <div onClick={() => {console.log("comment")}}>
                        <IconText icon={MessageOutlined} 
                        text="2" 
                        key="list-vertical-message" 
                        />
                    </div>
                ]}
                extra={
                    <img
                        width={272}
                        alt="logo"
                        src="https://gw.alipayobjects.com/zos/rmsportal/mqaQswcyDLcXyDKnZfES.png"
                    />
                }
            
            >
                <List.Item.Meta
                avatar={<Avatar 
                    style={{border: '1px solid gray', 
                        verticalAlign:'center', 
                        fontSize:'x-large',
                        backgroundColor: `${true ? 'purple': 'orange'} `
                    }} 
                    size='large'
                    // shape='square'
                >{
                    item.title.substring(0, 1).toUpperCase()
                    }
                </Avatar>}
                title={item.title}
                description={item.description}
                />
                <div className='cardContentLines' onClick={() => {
                    titleWasClicked()
                }}>
                    <a href={`/detail/${item.title}`}>{item.content}</a>
                </div>
           
                </List.Item>
            )}
        />
    </div>
  )
}
