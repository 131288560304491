import React from 'react'
import ReactDOM from 'react-dom/client';
import { PersistGate } from 'redux-persist/integration/react';

import App from './View/App'
import {store} from './Redux/ConfigureStore'
import { Provider } from 'react-redux'
import './index.css'
import RegistrationView from './View/Registration/RegistrationView';
import {
    createBrowserRouter,
    RouterProvider,
  } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root'))

const router = createBrowserRouter([
    {
      path: "/",
      element: <App></App>,
    },
    {
        path: "register",
        element: <RegistrationView></RegistrationView>,
    },
    {
      path: "/detail/:id",
      element: <div>hah</div>,
  },
  ]);

root.render(
        <Provider store={store}>
            {/* <PersistGate loading={null} persistor={persistor}> */}
                <RouterProvider router={router} >
                </RouterProvider>
            {/* </PersistGate> */}
        </Provider> 
)